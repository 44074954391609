import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import IntlMessages from "components/Utils/intlMessages";

const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold",
  field:
    "border border-gray-100 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-50 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-3xs border-error-50 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};

function PriceRange(props) {
  const {
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    minValue,
    maxValue,
    ...rest
  } = props;

  const formik = useFormikContext();
  const handleClearField = () => {
    formik.setFieldValue(name, {
      from: "",
      to: "",
    }); // Clear the field value by setting it to an empty string
  };
  return (
    <div className="w-full flex flex-col py-5 gap-5">
      <div className="flex items-center justify-between">
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        <span
          onClick={() => handleClearField()} // Updated the function call
          className="text-primary-500 cursor-pointer"
        >
          <IntlMessages id="global.clear" />
        </span>
      </div>
      <div className="flex flex-col justify-between gap-2">
        <div className="flex items-center gap-3">
          <span className="w-12">
            {" "}
            <IntlMessages id="global.from" />
          </span>
          <Field
            type="number"
            name={`${name}.from`}
            min={minValue}
            className={`${styles.field}`}
            value={formik.values[name]?.from || minValue} // Set default value to either formik value or minValue
          />
        </div>
        <div className="flex items-center gap-3">
          <span className="w-12">
            {" "}
            <IntlMessages id="global.to" />
          </span>
          <Field
            type="number"
            name={`${name}.to`}
            max={maxValue}
            className={`${styles.field}`}
            value={formik.values[name]?.to || maxValue} // Set default value to either formik value or maxValue
          />
        </div>
      </div>
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
  );
}

export default PriceRange;
