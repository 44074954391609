import { toast } from "react-toastify";
import HandleAxiosError from "utils/helpers/HandleAxiosError";
import ApiClient from "utils/plugins/ApiClient";

export const registerUser = (payload) =>
  ApiClient.post("/account/register/", payload)
    .then((respose) => respose.data)
    .catch((error) => {
      console.log(error);
      const errorMessages = HandleAxiosError(error);
      toast.error(error.response.data.message);
      errorMessages.forEach((errorMessage) => {
        toast.error(`${errorMessage.key}: ${errorMessage.message}`);
      });
      throw error;
    });

export const loginUser = (payload) =>
  ApiClient.post("/account/login/", payload)
    .then((respose) => respose.data)
    .catch((error) => {
      toast.error(error.response.data.message);

      throw error;
    });

export const forgetPassword = (payload) =>
  ApiClient.post("/account/forgot-password/send-code/", payload)
    .then((respose) => {
      toast.success(respose.data.message);
      return respose.data;
    })
    .catch((error) => {
      const errorMessages = HandleAxiosError(error);
      errorMessages.forEach((errorMessage) => {
        toast.error(`${errorMessage.key}: ${errorMessage.message}`);
      });
      throw error;
    });

export const verifyEmail = (payload) =>
  ApiClient.post("/account/forgot-password/check-code/", payload)
    .then((respose) => respose.data)
    .catch((error) => {
      const errorMessages = HandleAxiosError(error);
      errorMessages.forEach((errorMessage) => {
        toast.error(`${errorMessage.key}: ${errorMessage.message}`);
      });
      throw error;
    });

export const resetPassword = (payload) =>
  ApiClient.post("/account/forgot-password/reset/", payload)
    .then((respose) => {
      toast.success(respose.data.message);
      return respose.data;
    })
    .catch((error) => {
      const errorMessages = HandleAxiosError(error);
      errorMessages.forEach((errorMessage) => {
        toast.error(`${errorMessage.key}: ${errorMessage.message}`);
      });
      throw error;
    });
export const changePassword = (payload) =>
  ApiClient.post("/account/change-password/", payload)
    .then((respose) => {
      toast.success(respose.data.message);
      return respose.data;
    })
    .catch((error) => {
      const errorMessages = HandleAxiosError(error);
      errorMessages.forEach((errorMessage) => {
        toast.error(`${errorMessage.key}: ${errorMessage.message}`);
      });
      throw error;
    });
