import React, { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { useQueryClient } from "react-query";
import { get as _get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import listenForOutsideClick from "utils/helpers/listen-for-outside-clicks";
import { BuildingIcon } from "assets/icons";
import panelActions from "redux/userPanel/actions";
import Divider from "components/Divider/Divider";
import { ChevronDownIcon } from "assets/icons";
const BusinessesDropdown = ({ handleToggleProfileModal }) => {
  const dispatch = useDispatch();

  const userPanel = useSelector((state) => state.Panel);
  const User = useSelector((state) => state.User);
  const businesses = _get(User.data, "businesses", []);

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const switchPanel = (panel) => {
    setSelectedBusiness(panel);
  };

  useEffect(() => {
    const filteredBusiness = businesses.find(
      (item) => item.id === userPanel.panel
    );
    setSelectedBusiness(filteredBusiness || null);
  }, [userPanel.panel, businesses]);

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );
  const queryClient = useQueryClient();
  useEffect(() => {
    if (selectedBusiness !== null) {
      dispatch(panelActions.switchPanel(selectedBusiness?.id));
      queryClient.invalidateQueries();
    }
  }, [selectedBusiness]);

  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        className="flex gap-5 items-center bg-white bg-opacity-10 text-white p-3 h-full rounded-lg"
      >
        <div className="flex gap-2 items-center">
          <BuildingIcon />
          <div className="flex gap-1 flex-col items-start">
            <span className="text-sm text-white font-gotham-bold">
              {User.data.profile.name ?? selectedBusiness?.name}
            </span>
          </div>
        </div>

        <ChevronDownIcon size={20} />
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-sm z-50 float-start p-4 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[12rem]"
        }
      >
        {businesses.map((business, index) => (
          <>
            <button
              onClick={() => switchPanel(business)}
              className={`flex items-center gap-3 text-sm hover:text-primary font-gotham-medium w-full whitespace-nowrap bg-transparent text-gray-500 `}
            >
              <BuildingIcon className="text-primary-500" size={16} />
              {User.data.profile.name ?? business.name}
            </button>

            {businesses.length > index + 1 && (
              <Divider py="0" borderColor="border-gray-35" />
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default BusinessesDropdown;
