import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

export default function MainLayout({ children }) {
  return (
    <>
      <main>
        <section className="min-h-screen">{children}</section>
      </main>
    </>
  );
}
