/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import {
  HomeOutlineIcon,
  FileOutlineIcon,
  UsersOutlineIcon,
  CategoryOutlineIcon,
  BuildingsOutlineIcon,
  BagOutlineIcon,
  ToolsOutlineIcon,
  SettingsIcon,
} from "assets/icons/index";
import { left } from "@popperjs/core";

// import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
// import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-dark text-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4  ">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto ">
          <div className="px-5 w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start lg:items-center">
            <Link
              to="/admin/dashboard"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              <img
                src={require("assets/images/metrospeedy-logo.png").default}
                alt="Metrospeedy"
                className="w-52"
              />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setCollapseShow("bg-dark  py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              {/* <NotificationDropdown /> */}
            </li>
            <li className="inline-block relative">{/* <UserDropdown /> */}</li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-bluegray-500">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Metrospeedy
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none ">
              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/dashboard") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-xs uppercase font-bold block "
                  to="/admin/dashboard"
                >
                  <div className="flex items-center">
                    <HomeOutlineIcon className=" mr-2 text-xl " />
                    <span>DASHBOARD</span>
                  </div>
                </Link>
              </li>
              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/orders") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase  font-bold block"
                  to="/admin/orders"
                >
                  <div className="flex items-center">
                    <FileOutlineIcon className="mr-2 text-xl " />
                    <span> ORDERS</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/hubs") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase font-bold block"
                  to="/admin/hubs"
                >
                  <div className="flex items-center">
                    <CategoryOutlineIcon className="mr-2 text-xl " />
                    <span> HUBS</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/connections") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase font-bold block"
                  to="/admin/connections"
                >
                  <div className="flex items-center">
                    <BuildingsOutlineIcon className=" mr-2 text-xl " />
                    <span> CONNECTIONS</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/drivers") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase  font-bold block"
                  to="/admin/drivers"
                >
                  <div className="flex items-center">
                    <UsersOutlineIcon className=" mr-2 text-xl " />
                    <span> DRIVERS</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/vehicles") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase  font-bold block"
                  to="/admin/vehicles"
                >
                  <div className="flex items-center">
                    <ToolsOutlineIcon className="mr-2 text-xl " />
                    <span> VEHICLES</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/subscriptions") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase font-bold block"
                  to="/admin/subscriptions"
                >
                  <div className="flex items-center">
                    <BagOutlineIcon className=" mr-2 text-xl " />
                    <span> SUBSCRIPTION</span>
                  </div>
                </Link>
              </li>

              <li
                className={
                  "items-center mb-2  border-4 border-transparent hover:bg-white hover:border-l-primary-500   hover:bg-opacity-10 " +
                  (window.location.href.indexOf("/admin/settings") !== -1
                    ? "bg-white border-l-primary-500   bg-opacity-10"
                    : "")
                }
                style={{ padding: ".75rem 1.5rem" }}
              >
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase  font-bold block"
                  to="/admin/settings"
                >
                  <div className="flex items-center">
                    <SettingsIcon className=" mr-2 text-xl " />
                    <span> SETTINGS</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
