import { AsyncPaginate } from "react-select-async-paginate";
import React from "react";
import { ErrorMessage, useFormikContext } from "formik";
const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-50 text-dark placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded-lg appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};
function MultiSelect(props) {
  const {
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    value,
    loadOptions,
    ...rest
  } = props;
  const formik = useFormikContext();

  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <AsyncPaginate
        value={value}
        onChange={(e) => formik.setFieldValue(name, e)}
        loadOptions={loadOptions}
        className={touched && errors ? styles.fieldError : styles.field}
        name={name}
        isMulti
        closeMenuOnSelect={false}
        {...rest}
      />
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
  );
}
export default MultiSelect;
