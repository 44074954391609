import React from "react";
import { Field, ErrorMessage } from "formik";
import CustomSelect from "./CustomSelect";
const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[2px] w-full rounded appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-50 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-3xs border-error-50 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[2px] w-full rounded appearance-none disabled:text-gray-200/50",
};
function ReactSelect(props) {
  const { label, errors, touched, name, ...rest } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
        component={CustomSelect}
        {...rest}
      />
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default ReactSelect;
