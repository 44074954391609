import React from "react";
import { Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-50 text-dark placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};
function DatePickerField(props) {
  const {
    placeholder,
    setFieldValue,
    value,
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    ...rest
  } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <DatePicker
        placeholderText={placeholder}
        selected={value}
        onChange={(date) => setFieldValue(name, date || "")}
        showYearDropdown
        yearDropdownItemNumber={60}
        className={touched && errors ? styles.fieldError : styles.field}
        name={name}
        {...rest}
      />
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
  );
}
export default DatePickerField;
