import React, { useRef, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";

const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-50 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-3xs border-error-50 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};

function PriceRange(props) {
  const {
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    minValue,
    maxValue,
    ...rest
  } = props;

  const formik = useFormikContext();
  const containerRef = useRef(null);

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    containerRef.current.classList.remove("dragging");
  };

  const handleMouseMove = (e) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const offsetX = e.clientX - containerRect.left;
    const range = parseFloat(maxValue) - parseFloat(minValue);
    const value = (offsetX / containerWidth) * range + parseFloat(minValue);
    const clampedValue = Math.max(
      parseFloat(minValue),
      Math.min(parseFloat(maxValue), value)
    );
    const { from, to } = formik.values[name] || {};
    const updatedValue = {
      from: from,
      to: to,
    };
    if (
      containerRef.current
        .querySelector(".handle-from")
        .classList.contains("active")
    ) {
      updatedValue.from = clampedValue.toFixed(2);
    } else {
      updatedValue.to = clampedValue.toFixed(2);
    }
    formik.setFieldValue(name, updatedValue);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    containerRef.current.classList.add("dragging");
    const targetHandle = e.target.closest(".handle");
    containerRef.current.querySelectorAll(".handle").forEach((handle) => {
      handle.classList.remove("active");
    });
    targetHandle.classList.add("active");
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const { from, to } = formik.values[name] || {};

  const handleFromPosition = `${
    ((parseFloat(from) - minValue) / (maxValue - minValue)) * 100
  }%`;
  const handleToPosition = `${
    ((parseFloat(to) - minValue) / (maxValue - minValue)) * 100
  }%`;

  return (
    <div className="flex w-64 m-auto items-center h-32 justify-center">
      <div className="py-1 relative min-w-full" ref={containerRef}>
        <div className="h-2 bg-gray-500 rounded-full">
          <div
            className="absolute h-2 rounded-full bg-teal-600"
            style={{
              width: `${
                parseFloat(handleToPosition) - parseFloat(handleFromPosition)
              }%`,
              left: handleFromPosition,
            }}
          ></div>
          <div
            className="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer handle handle-from"
            onMouseDown={handleMouseDown}
            style={{ left: handleFromPosition }}
          >
            <div className="relative -mt-2 w-1">
              <div
                className="absolute z-40 opacity-100 bottom-100 mb-2 left-0 min-w-full"
                style={{ marginLeft: "-25px" }}
              >
                <div className="relative shadow-md">
                  <div className="bg-black -mt-8 text-white truncate text-xs rounded py-1 px-4">
                    {from}
                  </div>
                  <svg
                    className="absolute text-black w-full h-2 left-0 top-100"
                    viewBox="0 0 255 255"
                    preserveAspectRatio="none"
                  >
                    <polygon
                      className="fill-current"
                      points="0,0 127.5,127.5 255,0"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute h-4 flex items-center justify-center w-4 rounded-full bg-white shadow border border-gray-300 -ml-2 top-0 cursor-pointer handle handle-to"
            onMouseDown={handleMouseDown}
            style={{ left: handleToPosition }}
          >
            <div className="relative -mt-2 w-1">
              <div
                className="absolute z-40 opacity-100 bottom-100 mb-2 left-0 min-w-full"
                style={{ marginLeft: "-25px" }}
              >
                <div className="relative shadow-md">
                  <div className="bg-black -mt-8 text-white truncate text-xs rounded py-1 px-4">
                    {to}
                  </div>
                  <svg
                    className="absolute text-black w-full h-2 left-0 top-100"
                    viewBox="0 0 255 255"
                    preserveAspectRatio="none"
                  >
                    <polygon
                      className="fill-current"
                      points="0,0 127.5,127.5 255,0"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute text-gray-800 -ml-1 bottom-0 left-0 -mb-6">
            {minValue}
          </div>
          <div className="absolute text-gray-800 -mr-1 bottom-0 right-0 -mb-6">
            {maxValue}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col pb-4">
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {!hideErrorMessage && (
          <ErrorMessage component="p" className={styles.errorMsg} name={name} />
        )}
      </div>
    </div>
  );
}

export default PriceRange;
