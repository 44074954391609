import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import AutoComplete from "react-google-autocomplete";

const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-50 text-dark placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 p-[0.69rem] w-full rounded-lg appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 p-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};

function AddressAutoComplete(props) {
  const { value, label, errors, touched, name, ...rest } = props;
  const formik = useFormikContext(); // Access the formik context

  const [searchValue, setSearchValue] = useState(value || "");

  useEffect(() => {
    setSearchValue(value || "");
  }, [value]);

  const onPlaceSelected = (place) => {
    const address = place.formatted_address;
    setSearchValue(address); // Update the search input value
    formik.setFieldValue(name, address); // Set the field value when a place is selected
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue); // Update the search input value
    formik.setFieldValue(name, e.target.value);
  };

  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <AutoComplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onChange={handleChange}
        onPlaceSelected={onPlaceSelected}
        value={searchValue} // Use the search input value here
        language="en-us"
        className={touched && errors ? styles.fieldError : styles.field}
        options={{
          // search all places instead of all cities
          types: [0],
        }}
        {...rest}
      />
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default AddressAutoComplete;
