import HandleAxiosError from 'utils/helpers/HandleAxiosError'
import ApiClient from 'utils/plugins/ApiClient'
import { toast } from 'react-toastify'

export const getUserprofile = (payload) => {
  return ApiClient.get('/user/profile', payload)
    .then((response) => {
      // console.log(response.data.response.data)
      return response.data.response.data
    })
    .catch((error) => {
      throw HandleAxiosError(error)
    })
}
