import { lazy } from "react";

export const PUBLIC_ROUTE = {
  SIGN_IN: "/auth/signin",
  SIGN_UP: "/auth/signup",
  FORGET: "/auth/forget",
  VERIFY: "/auth/verify-email",
  RESET: "/auth/reset-password",
};

export const PRIVATE_ROUTE = {
  SELECT_BUSINESS: "/auth/select-business",
  DISPATCHER: "/dispatcher",
  ADMIN: "/admin/dashboard",
  DRIVERS: "/admin/drivers",
  MARKETPLACE: "/admin/drivers-marketplace",
  DRIVER_DETAILS: "/admin/drivers/:id",
  VEHICLES: "/admin/vehicles",
  ORDERS: "/admin/orders",
  CONNECTIONS: "/admin/connections",
  SUBSCRIPTIONS: "/admin/subscriptions",
  HUBS: "/admin/hubs",
  CONNECTION_DETAILS: "/admin/connections/:id",
  SETTINGS: "/admin/settings",
  ORDER_DETAILS: "/admin/orders/:id",
  MY_PROFILE: "/admin/myprofile",
  VEHICLE_DETAILS: "/admin/vehicles/:id",
  USER_DETAILS: "/admin/settings/users/:id",
  HUB_DETAILS: "/admin/hubs/:id",
  TEAM_DETAILS: "/admin/drivers-teams/:id",
  ADD_CONTRACT: "/admin/settings/add-contract",
  CONTRACT_DETAILS: "/admin/settings/contract-templates/:id",
};

export const publicRoutes = [
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import("views/Authentication/SignUp")),
    auth: true,
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import("views/Authentication/SignIn")),
    auth: true,
  },
  {
    path: PUBLIC_ROUTE.FORGET,
    exact: true,
    component: lazy(() => import("views/Authentication/ForgetPassword")),
    auth: true,
  },
  {
    path: PUBLIC_ROUTE.VERIFY,
    exact: true,
    component: lazy(() => import("views/Authentication/VerifyEmail")),
    auth: true,
  },

  {
    path: PUBLIC_ROUTE.RESET,
    exact: true,
    component: lazy(() => import("views/Authentication/ResetPassword")),
    auth: true,
  },
];

export const privateRoutes = [
  {
    path: PRIVATE_ROUTE.SELECT_BUSINESS,
    exact: true,
    component: lazy(() => import("views/Authentication/SelectBusiness")),
    auth: true,
  },
  {
    path: PRIVATE_ROUTE.DISPATCHER,
    exact: true,
    component: lazy(() => import("views/Dispatcher/Home")),
    // dispatcher: true,
  },
  {
    path: PRIVATE_ROUTE.ADMIN,
    exact: true,
    component: lazy(() => import("views/Dashboard/Home")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.DRIVERS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Drivers")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.DRIVER_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/DriverDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.TEAM_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/TeamDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.VEHICLES,
    exact: true,
    component: lazy(() => import("views/Dashboard/Vehicles")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.ORDERS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Orders")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.CONNECTIONS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Connections")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.HUBS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Hubs")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.SETTINGS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Settings")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.CONNECTION_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/ConnectionDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.ORDER_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/OrderDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.SUBSCRIPTIONS,
    exact: true,
    component: lazy(() => import("views/Dashboard/Subscriptions")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.MY_PROFILE,
    exact: true,
    component: lazy(() => import("views/Dashboard/MyProfile")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.VEHICLE_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/VehicleDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.USER_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/UserDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.MARKETPLACE,
    exact: true,
    component: lazy(() => import("views/Dashboard/MarketPlace")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.HUB_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/HubDetails")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.ADD_CONTRACT,
    exact: true,
    component: lazy(() => import("views/Dashboard/AddContract")),
    admin: true,
  },
  {
    path: PRIVATE_ROUTE.CONTRACT_DETAILS,
    exact: true,
    component: lazy(() => import("views/Dashboard/ContractDetails")),
    admin: true,
  },
];
export const NotFound = lazy(() => import("views/404"));
