import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { cls } from "utils/helpers/cls";

const classes = {
  base: "focus:outline-none transition ease-in-out duration-300",
  disabled: " cursor-not-allowed",
  loading:
    "flex items-center justify-center gap-2 opacity-50 cursor-not-allowed",
  pill: "rounded-full",
  danger: "bg-error-900",
  icon: "w-8 h-8 !p-0 !text-4xs",
  size: {
    auto: "",
    small: "px-6 py-[1px] text-xs leading-2",
    normal: "px-6 py-[5px] text-xs leading-2",
    medium: "px-6 py-[7px] text-base leading-4",
    large:
      "px-3 py-[5px] md:px-6 md:py-[9px] text-xs md:md:text-base leading-4",
    "x-large": "px-6 py-3 text-2xs leading-6",
    "2x-large": "px-6 py-4 text-2xs leading-6",
  },
  variant: {
    text: "text-primary-500 flex items-center gap-1 p-0 ltr:font-gotham-medium rtl:font-semibold",
    solid:
      "text-white border border-primary-500 hover:border-primary-200 active:border-primary-100 bg-primary-500 hover:bg-primary-200 active:bg-primary-100 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-20 rounded disabled:bg-gray-500 disabled:border-gray-500 ltr:font-gotham-medium rtl:font-semibold",
    outlined:
      "text-primary-500 border border-primary-500 hover:border-primary-200 hover:text-primary-200 active:border-primary-100 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-20  rounded disabled:text-gray-2000 disabled:border-gray-500 ltr:font-gotham-regular rtl:font-normal",
    gray: "text-gray-100 border border-gray-80 hover:border-primary-200 hover:text-primary-200 active:border-primary-100 focus:ring-2 focus:ring-gray-80 focus:ring-opacity-20  rounded disabled:text-gray-2000 disabled:border-gray-500 ltr:font-gotham-regular rtl:font-normal",
    danger:
      "text-error-50 border border-error-50 focus:ring-2 focus:ring-error-50 focus:ring-opacity-20 rounded disabled:text-gray-2000 disabled:border-gray-500 ltr:font-gotham-medium rtl:font-semibold",
    ghost:
      "text-primary-500 bg-primary-100 px-[3.5rem] hover:text-primary-200  disabled:text-gray-2000 ltr:font-gotham-medium rtl:font-semibold",
    white: "text-gray-500 bg-white",
    cancel: "bg-gray-5 text-gray-200 font-gotham-medium",
    download:
      "flex items-center rounded text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold cursor-pointer px-[0.69rem] py-[0.7rem] border-2 border-dashed border-gray-200",
  },
  auto: "px-[9px]",
};

const Button = forwardRef(
  (
    {
      children,
      type = "button",
      className,
      variant = "solid",
      size = "normal",
      pill,
      disabled = false,
      loading = false,
      icon = false,
      auto = false,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      loading={loading}
      type={type}
      className={cls(`
                ${classes.base}
                ${classes.size[size]}
                ${classes.variant[variant]}
                ${pill && classes.pill}
                ${disabled && classes.disabled}
                ${loading && classes.loading}
                ${icon && classes.icon}
                ${auto && classes.auto}
                ${className}
            `)}
      {...props}
    >
      {loading && (
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-5 h-5 border-2 border-current rounded-full animate-spin"
        ></div>
      )}
      {children}
    </button>
  )
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.oneOf(["submit", "button"]),
  className: PropTypes.string,
  pill: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(["solid", "outlined", "gray", "danger", "ghost"]),
  size: PropTypes.oneOf(["small", "normal", "large", "x-large", "2x-large"]),
};

export default Button;
