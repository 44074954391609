import React, { Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { get as _get } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";
import ScrollToTop from "utils/helpers/ScrollToTop";
import Loader from "components/Utils/loader";
import { publicRoutes, privateRoutes, NotFound } from "./route.constants";
import ErrorBoundary from "./ErrorBoundary";
import DashboardLayout from "./layouts/DashboardLayout";
import DispatcherLayout from "./layouts/DispatcherLayout";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
function PublicRoute({ children, layout: Layout, ...rest }) {
  return <Route {...rest} render={() => <Layout>{children} </Layout>} />;
}

function PrivateRoute({ children, layout: Layout, ...rest }) {
  const User = useSelector((state) => state.User || {}, shallowEqual);
  // console.log(User);
  const isLoggedIn = _get(User.data, "token", false);

  if (isLoggedIn) {
    return <Route {...rest} render={() => <Layout>{children} </Layout>} />;
  }
  if (!isLoggedIn) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/auth/signin",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
  return null;
}
function Routes(props) {
  reactIntl.injectIntl(props.intl);

  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User.data, "token", false);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/">
                {isLoggedIn ? (
                  <Redirect to="/admin/dashboard" />
                ) : (
                  <Redirect to="/auth/signin" />
                )}
              </Route>
              {publicRoutes.map((route) => (
                <PublicRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  layout={route.auth ? AuthLayout : MainLayout}
                >
                  <route.component />
                </PublicRoute>
              ))}
              {privateRoutes.map((route) => (
                <PrivateRoute
                  exact={route.exact}
                  key={route.path}
                  path={route.path}
                  layout={
                    route.auth
                      ? AuthLayout
                      : route.dispatcher
                      ? DispatcherLayout
                      : route.admin
                      ? DashboardLayout
                      : MainLayout
                  }
                >
                  <route.component />
                </PrivateRoute>
              ))}
              <Route>
                <MainLayout>
                  <NotFound />
                </MainLayout>
              </Route>
            </Switch>
          </ScrollToTop>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default injectIntl(Routes);
