// import IntlMessages from "components/Utils/intlMessages";
import * as Yup from "yup";
// import Messages from "views/Authentication/i18nStrings";
import { passwordPattern } from "utils/helpers/validators/regexPattern";
export const ResetPassSchema = Yup.object().shape({
  // old_password: Yup.string()
  //   .matches(
  //     passwordPattern,
  //     "Password should contain at least one uppercase, one lowercase, 2 numbers "
  //   )
  //   .min(8, "Password length shouldn't be smaller than 8")
  //   .required("This field is required"),
  password: Yup.string()
    // .matches(passwordPattern, Messages.getFormatMessage("invalidPassword"))
    // .min(8, <IntlMessages id="global.tooShort" values={{ len: "8" }} />)
    // .required(<IntlMessages id="global.required" />),
    .matches(
      passwordPattern,
      "Password should contain at least one uppercase, one lowercase, 2 numbers "
    )
    .min(8, "Password length shouldn't be smaller than 8")
    .required("This field is required"),
  password_confirmation: Yup.string()
    // .matches(passwordPattern, Messages.getFormatMessage("invalidPassword"))
    // .min(8, <IntlMessages id="global.tooShort" values={{ len: "8" }} />)
    // .required(<IntlMessages id="global.required" />)
    .matches(
      passwordPattern,
      "Password should contain at least one uppercase, one lowercase, 2 numbers "
    )
    .min(8, "Password length shouldn't be smaller than 8")
    .required("This field is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Passwords should match"
      // <IntlMessages id="global.passwordsMustMatch" />
    ),
  logout_all_devices: Yup.boolean().default(false),
});
