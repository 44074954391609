import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { VisibilityIcon, VisibilityOffIcon } from "assets/icons";
const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-100 text-gray-200 placeholder:text-gray-100  w-full rounded appearance-none disabled:text-gray-200/50 focus-within:border-primary-500",
  errorMsg: "text-error-50 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-50 text-gray-200 placeholder:text-gray-100  w-full rounded appearance-none disabled:text-gray-200/50",
};
function LooseInput(props) {
  const { name, errors, touched, label, className, classBox, ...rest } = props;

  return (
    <>
      <Field id={name} name={name}>
        {(formik) => {
          const { field } = formik;
          return (
            <div
              className={
                touched && errors
                  ? styles.fieldError + " " + classBox
                  : styles.field + " " + classBox
              }
            >
              <input
                className={`text-sm border-none w-full h-10 bg-transparent rounded-lg focus:ring-transparent ${className}`}
                {...field}
                {...rest}
              />
            </div>
          );
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </>
  );
}
export default LooseInput;
