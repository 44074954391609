import React from "react";

const Progress = ({ percentage, color }) => {
  return (
    <div className="w-full bg-gray-500 rounded h-2">
      <div
        className={`bg-${color} h-2 rounded`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default Progress;
