import React, { useRef, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { ClockIcon } from "assets/icons";
import "./datepicker.css"
import { ArrowDownIcon } from "assets/icons";
const styles = {
  label: "text-white text-sm ltr:font-gotham-medium rtl:font-semibold",
  field:
    "border border-gray-80 text-dark focus:outline-none focus:shadow-outline py-1 w-full rounded appearance-none",
  option: "flex gap-2 items-center",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-sm text-dark bg-error-50 focus:outline-none focus:shadow-outline focus:ring-error-900  py-2 w-full rounded appearance-none border-error-900",
};

function DatePickerField(props) {
  const {
    buttonStyled,
    setFieldValue,
    value,
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    setStart,
    setEnd,
    start,
    end,
    ...rest
  } = props;

  const datePickerRef = useRef(null);

  const today = new Date();
  const minDate = new Date();
  minDate.setDate(today.getDate() - 29);

  const [dateRange, setDateRange] = useState([start, end]);
  const [startDate, endDate] = dateRange;

  const CustomInput = ({ value, onClick }) => (
    <div className="relative rounded-3xl text-sm bg-gray-30 px-5 py-3 flex items-center text-white">
      <ClockIcon
        className="mr-2 cursor-pointer"
        size={18}
        onClick={() => datePickerRef.current.setOpen(true)}
      />
      {!startDate?(
      <p className="cursor-pointer" onClick={() => datePickerRef.current.setOpen(true)}>Select Date</p>
      ):(
        <></>
      )}
      
      <span className="cursor-pointer" onClick={onClick}>
        {value}
      </span>
     
    </div>
  );

  // Helper function to convert the formatted date string to a Date object
  const parseDateStringToDate = (dateString) => {
    const [startDateString, endDateString] = dateString.split(" - ");
    const startDate = parse(startDateString, "MMMM d, h:mm aa", new Date());
    const endDate = parse(endDateString, "MMMM d, h:mm aa", new Date());

    return [startDate, endDate];
  };

  // Update the date format before setting the field value
  const formatDate = (startDate, endDate) => {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    return { formattedStartDate, formattedEndDate };
    // return `${formattedStartDate} - ${formattedEndDate}`;س
  };
  const formik = useFormikContext();
  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (Array.isArray(dates) && dates.length === 2) {
      const [startDate1, endDate1] = dates;
      if (endDate1) {
        setStart(startDate1);
        setEnd(endDate1);
        // const formattedRange = formatDate(startDate1, endDate1);
        // formik.setFieldValue(name, formattedRange);
      }
    } else {
      formik.setFieldValue(name, "");
    }
  };

  return (
    <div className="w-full flex flex-col">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <DatePicker
        ref={datePickerRef}
        // selected={
        //   value
        //     ? value.split(" - ").map((date) => parseDateStringToDate(date))
        //     : null
        // }
        onChange={handleDateChange}
        // showYearDropdown
        // yearDropdownItemNumber={60}
        // showTimeSelect
        // timeFormat="HH"
        // timeIntervals={15}
        // timeCaption="Time"
        // dateFormat="MMMM d, h:mm aa"
        customInput={<CustomInput />}
        className={touched && errors ? styles.fieldError : styles.field}
        name={name}
        startDate={startDate}
        endDate={endDate}
        placeholderText="select date"
        //maxDate={today}
        minDate={minDate}
        selectsRange
        {...rest}
      />
      {!hideErrorMessage && (
        <ErrorMessage component="p" className={styles.errorMsg} name={name} />
      )}
    </div>
  );}

export default DatePickerField;
