import history from "utils/helpers/history";

import { actionTypes } from "./actions";

const initState = {
  idToken: null,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },

  changePassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: false,
    error: null,
  },
};
// register
const registerRequest = (state, payload) => ({
  ...state,
  register: { isLoading: true },
});
const registerRequestSucces = (state, payload) => ({
  ...state,
  register: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const registerRequestFailed = (state, payload) => ({
  ...state,
  register: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// verify
const verifyEmailRequest = (state, payload) => ({
  ...state,
  verify: { isLoading: true },
});
const verifyEmailRequestSuccess = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const verifyEmailRequestFailed = (state, payload) => ({
  ...state,
  verify: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// login
const loginRequest = (state, payload) => ({
  ...state,
  login: { isLoading: true },
});
const loginRequestSucces = (state, payload) => {
  // console.log("login payload", payload);
  localStorage.setItem("@metrospeedy__token", payload.data.token);
  // window.location.reload()
  history.push("/auth/select-business");
  return {
    ...state,
    login: {
      isLoading: false,
      isSuccess: true,
      successMessage: payload?.message,
      isFailed: false,
      error: null,
    },
  };
};
const loginRequestFailed = (state, payload) => ({
  ...state,
  login: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// forget pass
const forgetPasswordRequest = (state, payload) => ({
  ...state,
  forgetPassword: { isLoading: true },
});
const forgetPasswordSucces = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const forgetPasswordFailed = (state, payload) => ({
  ...state,
  forgetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
// reset pass
const resetPasswordRequest = (state, payload) => ({
  ...state,
  resetPassword: { isLoading: true },
});
const resetPasswordSucces = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const resetPasswordFailed = (state, payload) => ({
  ...state,
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});
const changePasswordRequest = (state, payload) => ({
  ...state,
  changePassword: { isLoading: true },
});
const changePasswordSucces = (state, payload) => ({
  ...state,
  changePassword: {
    isLoading: false,
    isSuccess: true,
    successMessage: payload?.message,
    isFailed: false,
    error: null,
  },
});
const changePasswordFailed = (state, payload) => ({
  ...state,
  changePassword: {
    isLoading: false,
    isSuccess: false,
    successMessage: null,
    isFailed: true,
    error: payload,
  },
});

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    // register
    case actionTypes.REGISTER_REQUEST:
      return registerRequest(state, payload);
    case actionTypes.REGISTER_REQUEST_SUCCESS:
      return registerRequestSucces(state, payload);
    case actionTypes.REGISTER_REQUEST_FAILED:
      return registerRequestFailed(state, payload);
    // verify
    case actionTypes.VERIFY_EMAIL_REQUEST:
      return verifyEmailRequest(state, payload);
    case actionTypes.VERIFY_EMAIL_REQUEST_SUCCESS:
      return verifyEmailRequestSuccess(state, payload);
    case actionTypes.VERIFY_EMAIL_REQUEST_FAILED:
      return verifyEmailRequestFailed(state, payload);
    // login
    case actionTypes.LOGIN_REQUEST:
      return loginRequest(state, payload);
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      return loginRequestSucces(state, payload);
    case actionTypes.LOGIN_REQUEST_FAILED:
      return loginRequestFailed(state, payload);
    // forget
    case actionTypes.FORGET_PASSWORD_REQUEST:
      return forgetPasswordRequest(state, payload);
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return forgetPasswordSucces(state, payload);
    case actionTypes.FORGET_PASSWORD_FAILED:
      return forgetPasswordFailed(state, payload);
    // reset
    case actionTypes.RESET_PASSWORD_REQUEST:
      return resetPasswordRequest(state, payload);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSucces(state, payload);
    case actionTypes.RESET_PASSWORD_FAILED:
      return resetPasswordFailed(state, payload);
    //change
    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return changePasswordRequest(state, payload);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSucces(state, payload);
    case actionTypes.CHANGE_PASSWORD_FAILED:
      return changePasswordFailed(state, payload);
    // case actionTypes.LOGIN_REQUEST:
    //   return loginRequest(state, payload)
    // case actionTypes.LOGIN_REQUEST_SUCCESS:
    //   return {
    //     idToken: payload.token,
    //   }
    case actionTypes.LOGOUT_SUCCESS:
      // window.location.reload()
      // console.log(payload);
      if (payload.shouldRedirect) {
        // localStorage.removeItem('@metrospeedy_user_panel')
        // localStorage.removeItem('organization-handle')
        // window.location.replace('/landing')
        window.location.reload();
      }
      return initState;
    case actionTypes.FLUSH_AUTH_REDUCER:
      return initState;
    default:
      return state;
  }
}
