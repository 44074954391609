import { GoBell } from "react-icons/go";
import {
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineLogout,
  MdKeyboardArrowDown,
  MdLockOutline,
  MdOutlinePayment,
} from "react-icons/md";
import {
  AiOutlinePlus,
  AiFillClockCircle,
  AiOutlineHome,
  AiOutlineFileText,
  AiFillCheckCircle,
  AiFillFileText,
} from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import {
  FaRegUser,
  FaUserAlt,
  FaTruck,
  FaRegCalendarAlt,
  FaTelegramPlane,
  FaRegCircleCheck,
  FaBars,
} from "react-icons/fa";
import { FiSearch, FiUsers, FiZap } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import {
  BsFillBuildingsFill,
  BsChatSquareQuote,
  BsBuildings,
  BsBagCheck,
  BsTools,
  BsBoxSeamFill,
  BsGraphUpArrow,
  BsGraphDownArrow,
  BsFillXCircleFill,
  BsGrid3X3GapFill,
  BsFillPersonPlusFill,
  BsPerson,
  BsPersonPlus,
  BsCapslock,
  BsShopWindow,
  BsCalendar4Week,
  BsGeoAlt,
  BsPatchExclamationFill,
  BsExclamationCircle,
  BsThreeDotsVertical,
  BsPlusCircleFill,
  BsPlusCircle,
} from "react-icons/bs";
import {
  BiChevronDown,
  BiChevronUp,
  BiChevronRight,
  BiChevronLeft,
  BiHash,
  BiCategory,
  BiHomeAlt2,
  BiUserCheck,
  BiUserPlus,
  BiLink,
  BiEditAlt,
} from "react-icons/bi";
import { RiSettings3Line, RiDeleteBin5Line } from "react-icons/ri";
import { TbLogout, TbCircleCheck } from "react-icons/tb";
import { IoMdPin } from "react-icons/io";
import { IoNotificationsOutline, IoDocumentText } from "react-icons/io5";
import { PiSlidersHorizontal } from "react-icons/pi";

// exports
export const ChevronDownIcon = BiChevronDown;
export const ChevronUpIcon = BiChevronUp;
export const VisibilityIcon = MdOutlineVisibility;
export const VisibilityOffIcon = MdOutlineVisibilityOff;
export const UserIcon = FaRegUser;
export const UserSolidIcon = FaUserAlt;
export const UsersIcon = HiUsers;

export const SettingsIcon = RiSettings3Line;
export const LogoutIcon = TbLogout;
export const NotificationsIcon = IoNotificationsOutline;
export const PlusIcon = AiOutlinePlus;
export const SearchIcon = FiSearch;
export const BuildingIcon = BsFillBuildingsFill;
export const PinIcon = IoMdPin;
export const ChatIcon = BsChatSquareQuote;
export const ClockIcon = AiFillClockCircle;
export const HashIcon = BiHash;
export const BoxIcon = BsBoxSeamFill;
export const GraphUpArrowIcon = BsGraphUpArrow;
export const GraphDownArrowIcon = BsGraphDownArrow;
export const CheckCircleIcon = AiFillCheckCircle;
export const XCircleIcon = BsFillXCircleFill;
export const HomeOutlineIcon = BiHomeAlt2;
export const FileOutlineIcon = AiOutlineFileText;
export const UsersOutlineIcon = FiUsers;
export const CategoryOutlineIcon = BiCategory;
export const BuildingsOutlineIcon = BsBuildings;
export const BagOutlineIcon = BsBagCheck;
export const ToolsOutlineIcon = BsTools;
export const VehicleIcon = FaTruck;
export const GridIcon = BsGrid3X3GapFill;
export const PersonIcon = BsFillPersonPlusFill;
export const PersonOutlineIcon = BsPerson;
export const PersonPlusIcon = BsPersonPlus;
export const CapslockIcon = BsCapslock;
export const ShopWindowIcon = BsShopWindow;
export const ChevronRightIcon = BiChevronRight;
export const ChevronLeftIcon = BiChevronLeft;
export const SlidersIcon = PiSlidersHorizontal;
export const CalenderWeekIcon = BsCalendar4Week;
export const CalenderIcon = FaRegCalendarAlt;
export const ArrowDownIcon = MdKeyboardArrowDown;
export const UserCheck = BiUserCheck;
export const UserAddOutline = BiUserPlus;
export const LockIcon = MdLockOutline;
export const ZapIcon = FiZap;
export const CheckIcon = FcCheckmark;
export const DetailsIcon = IoDocumentText;
export const FileFillIcon = AiFillFileText;
export const LocationIcon = BsGeoAlt;
export const NoDataIcon = BsPatchExclamationFill;
export const TelegramPlaneIcon = FaTelegramPlane;
export const LinkIcon = BiLink;
export const BellIcon = GoBell;
export const PaymentOutlineIcon = MdOutlinePayment;
export const DeleteBinIcon = RiDeleteBin5Line;
export const EditIcon = BiEditAlt;
export const CircleCheckIcon = TbCircleCheck;
export const ExclamationCircleIcone = BsExclamationCircle;
export const MenuIcon = FaBars;
export const DotsIcon = BsThreeDotsVertical;
export const PlusCircleIcon = BsPlusCircleFill;
export const PlusCircleOutlineIcon = BsPlusCircle;
