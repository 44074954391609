import { createPopper } from "@popperjs/core";
import { BellIcon } from "assets/icons";
import { NotificationsIcon } from "assets/icons";
import Divider from "components/Divider/Divider";
import { useEffect } from "react";
import { useRef, useState } from "react";
import listenForOutsideClicks from "utils/helpers/listen-for-outside-clicks";

const NotificationsDropdown = () => {
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);

  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [listening, setListening] = useState(false);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const notifications = [
    {
      title: "New admin registration",
      description: "Khalid Ali has been registered as admin successfully ",
      time: "12/02/2023 at 02:35 PM",
    },
    {
      title: "New admin registration",
      description: "Khalid Ali has been registered as admin successfully ",
      time: "12/02/2023 at 02:35 PM",
    },
    {
      title: "New admin registration",
      description: "Khalid Ali has been registered as admin successfully ",
      time: "12/02/2023 at 02:35 PM",
    },
    {
      title: "New admin registration",
      description: "Khalid Ali has been registered as admin successfully ",
      time: "12/02/2023 at 02:35 PM",
    },
  ];
  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      btnDropdownRef,
      popoverDropdownRef,
      setDropdownPopoverShow
    )
  );
  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-primary-1000 rounded-lg "
      >
        <NotificationsIcon size={20} />
        <span className="sr-only">Notifications</span>
        <div className="absolute inline-flex items-center justify-center w-2 h-2 text-xs font-bold text-white bg-error-600 rounded-full -top-1 -end-1 "></div>
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-sm z-50 float-start p-4 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[10rem]"
        }
      >
        <h5 className="text-base font-gotham-bold flex items-center text-blue-500">
          <BellIcon
            size={30}
            className="text-primary-500 mr-3 bg-primary-100 rounded-xl p-2"
          />
          Notifications
        </h5>
        <Divider borderColor="border-gray-15" py="0" />
        {notifications.map((notification, index) => (
          <section key={"notifications" + index}>
            <h4 className="text-gray-500 text-sm font-gotham-medium">
              {notification.title}
            </h4>
            <p className="text-gray-200 text-sm mb-2">
              {notification.description}
            </p>
            <h6 className="text-primary-50 text-xs">{notification.time}</h6>
            {index < notifications.length - 1 && (
              <div className="mt-4">
                <Divider borderColor="border-gray-15" py="0" />
              </div>
            )}
          </section>
        ))}
      </div>
    </>
  );
};

export default NotificationsDropdown;
