import React from "react";
import { Field, ErrorMessage } from "formik";
const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-sm border-gray-50 text-dark focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",
  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};
function Select(props) {
  const {
    firstOption,
    placeholder,
    label,
    errors,
    touched,
    name,
    options,
    ...rest
  } = props;
  return (
    <div className="w-full flex flex-col pb-4">
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        as="select"
        className={touched && errors ? styles.fieldError : styles.field}
        id={name}
        name={name}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled hidden selected>
            {placeholder}
          </option>
        )}
        {firstOption && <option value="">{firstOption}</option>}
        {options?.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}

export default Select;
