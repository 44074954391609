import React from "react";

const ToggleButtons = ({ view, setView }) => {
  const handleButtonClick = (button) => {
    setView(button);
  };

  return (
    <div className="w-auto flex bg-gray-30 rounded-full">
      <button
        className={`toggle-button ${
          view === "map" ? "bg-gray-400" : "bg-gray-30"
        } rounded-full text-white ltr:font-gotham-medium px-12 py-2`}
        onClick={() => handleButtonClick("map")}
      >
        Map
      </button>
      <button
        className={`toggle-button ${
          view === "table" ? "bg-gray-400" : "bg-gray-30"
        } rounded-full text-white ltr:font-gotham-medium px-12 py-2`}
        onClick={() => handleButtonClick("table")}
      >
        Table
      </button>
    </div>
  );
};

export default ToggleButtons;
