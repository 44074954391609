import React from "react";
import Input from "./Fields/Input.js";
import PriceRange from "./Fields/PriceRange.js";
import PriceRangeSlider from "./Fields/PriceRangeSlider.js";
import Password from "./Fields/Password.js";
import LooseInput from "./Fields/LooseInput.js";
import HashedInput from "./Fields/HashedInput.js";
import TextArea from "./Fields/TextArea.js";
import Select from "./Fields/Select.js";
import ReactSelect from "./Fields/ReactSelect";
import Expiry from "./Fields/Expiry.js";
import RadioButton from "./Fields/RadioButton.js";
import RadioTextOption from "./Fields/RadioTextOption.js";
import CheckBox from "./Fields/CheckBox.js";
import DatePicker from "./Fields/DatePicker";
import DateTimePicker from "./Fields/DateTimePicker.js";
import DateTextPicker from "./Fields/DateTextPicker";
import DateRangePicker from "./Fields/DateRangePicker";
import PhoneNumber from "./Fields/PhoneNumber";
import File from "./Fields/File.js";
import MultiSelect from "./Fields/MultiSelect.js";
import AddressAutocomplete from "./Fields/AddressAutocomplete.js";

function FormikController(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "pricerange":
      return <PriceRange {...rest} />;
    case "pricerangeslider":
      return <PriceRangeSlider {...rest} />;
    case "password":
      return <Password {...rest} />;
    case "looseinput":
      return <LooseInput {...rest} />;
    case "hashedinput":
      return <HashedInput {...rest} />;
    case "textarea":
      return <TextArea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "reactSelect":
      return <ReactSelect {...rest} />;
    case "expiry":
      return <Expiry {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "textoption":
      return <RadioTextOption {...rest} />;
    case "checkbox":
      return <CheckBox {...rest} />;
    case "datePicker":
      return <DatePicker {...rest} />;
    case "dateTimePicker":
      return <DateTimePicker {...rest} />;
    case "dateTextPicker":
      return <DateTextPicker {...rest} />;
    case "dateRangePicker":
      return <DateRangePicker {...rest} />;
    case "phoneNumber":
      return <PhoneNumber {...rest} />;
    case "file":
      return <File {...rest} />;
    case "multiSelect":
      return <MultiSelect {...rest} />;
    case 'address': 
      return <AddressAutocomplete {...rest} />;
    default:
      return null;
  }
}
export default FormikController;
