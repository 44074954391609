export default function HandleAxiosError(error) {
  try {
    if (error.response && error.response.data && error.response.data.data) {
      const responseData = error.response.data.data;

      const errorMessages = [];

      for (const key in responseData) {
        if (responseData.hasOwnProperty(key)) {
          const errors = responseData[key];
          errorMessages.push({
            key: key,
            message: errors[0],
          });
        }
      }

      return errorMessages;
    }

    // If no specific errors found, check if there's a generic message
    if (error.response && error.response.data && error.response.data.message) {
      return [
        {
          key: "error",
          message: error.response.data.message,
        },
      ];
    }

    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return [
        {
          key: "error",
          message: "No response received from the server.",
        },
      ];
    }

    // Something happened in setting up the request that triggered an Error
    return [
      {
        key: "error",
        message: error.message || "An unexpected error occurred.",
      },
    ];
  } catch (err) {
    return [
      {
        key: "error",
        message: "An unexpected error has been caught.",
      },
    ];
  }
}
