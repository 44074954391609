import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppLocale from "config/translation";
import client from "utils/plugins/ApiClient";
import { get as _get } from "lodash";
import { actions as authActions } from "redux/auth/actions";
import { actions as userprofileActions } from "redux/userprofile/actions";
import languageSwitcherActions from "redux/languageSwitcher/actions";
import { dateDiffInDays } from "utils/helpers/dateDiffInDays";
import { useQueryClient } from "react-query";

function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const currentAppLocale = AppLocale[locale];

  const User = useSelector((state) => state.User);

  const isLoggedIn = _get(User, "token", false);
  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (locale === "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
      client.defaults.headers.Language = "ar";
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
      client.defaults.headers.Language = "en";
    }
    queryClient.resetQueries({ exact: true });
  }, [locale]);

  useEffect(() => {
    if (localStorage.getItem("@metrospeedy-current_currency")) {
      client.defaults.headers["currency"] = localStorage.getItem(
        "@metrospeedy-current_currency"
      );
    } else {
      localStorage.setItem("@metrospeedy-current_currency", "USD");
      client.defaults.headers["currency"] = "USD";
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const lastDate = new Date(User?.user?.last_login_at);
      const currentDate = new Date();
      const difference = dateDiffInDays(lastDate, currentDate);

      if (localStorage.getItem("@metrospeedy-remember")) {
        if (difference > 10) {
          dispatch(authActions.logout());
        }
      } else {
        if (difference != 0) {
          dispatch(authActions.logout());
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!isProfileLoading && !profile && isLoggedIn) {
      dispatch(userprofileActions.userprofileRequest());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isProfileLoading && profile && isLoggedIn) {
      profile?.default_locale === "en"
        ? dispatch(languageSwitcherActions.changeLanguage("english"))
        : dispatch(languageSwitcherActions.changeLanguage("arabic"));
    }
  }, [isLoggedIn, profile?.default_locale]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <>
        {/* {locale === "ar"} */}
        {children}
      </>
    </IntlProvider>
  );
}

export default AppProvider;
