import axios from "axios";
import history from "utils/helpers/history";
import { store, dispatch } from "../../redux";
import { actionTypes } from "redux/auth/actions";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_BE_API,
  validateStatus: (status) => {
    if (status === 401 || status === 403) {
      // logout
      // history.push('/signin')
      dispatch({
        type: actionTypes.LOGOUT,
      });
      return false;
    }
    if (status >= 200 && status <= 300) return true;
    return false;
  },
});

client.interceptors.request.use(function (config) {
  const panel = store.getState().Panel.panel;
  // console.log(panel);
  config.headers["business"] = panel;

  return config;
});
const token = localStorage.getItem("@metrospeedy__token") || "null";

client.defaults.headers.common.Authorization = `Token ${token}`;
client.defaults.headers.Language = "en";
client.defaults.headers["User-Agent-Type"] = "web";
client.defaults.headers["currency"] = "USD";

// client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default client;
