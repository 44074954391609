import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DispatcherNavbar from "components/Navbars/DispatcherNavbar";
import DispatcherFooter from "components/Footers/DispatcherFooter";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";

const DispatcherLayout = ({
  children,
  google,
  mapLocations,
  view,
  setView,
}) => {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const [map, setMap] = useState({
    lat: 40.494978, // Replace with your desired default latitude
    lng: -74.479741, // Replace with your desired default longitude
  });
  const ZOOM_LEVEL = 10; // Replace with your desired zoom level

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMap({ lat: latitude, lng: longitude });
        },
        () => {
          // Handle error when geolocation is not available
          console.error("Error getting current position.");
        }
      );
    } else {
      // Handle case when geolocation is not supported
      console.error("Geolocation is not supported.");
    }
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "hidden", background: "#272727" }}>
      {view === "map" ? (
        <Map
          google={google}
          zoom={ZOOM_LEVEL}
          initialCenter={map}
          style={{ width: "100%", height: "100%" }}
          disableDefaultUI
          styles={[
            {
              elementType: "geometry",
              stylers: [{"color": "#333A40"}]
            },
            {
              featureType: "administrative",
              elementType: "labels.text",
              stylers: [{"visibility": "off"}],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [{"color": "#333A40"}],
            },
            {
              featureType: "landscape",
              elementType: "labels.text",
              stylers: [{"visibility": "off"}],
            },
            {
              featureType: "poi",
              stylers: [{"visibility": "off"}],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{"color": "#151e26"}],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{"color": "#a4a5a6"}],
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [{"color": "#151e26"}],
            },
            {
              featureType: "transit",
              stylers: [{"visibility": "off"}],
            },
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [{"color": "#070a0d"}],
            },
            {
              featureType: "water",
              elementType: "labels.text",
              stylers: [{"visibility": "off"}],
            },
            // {
            //   featureType: "road.local",
            //   stylers: [
            //     {"weight": "2"}
            //   ]
            // },
            // {
            //   featureType: "road.arterial",
            //   stylers: [
            //     {"weight": "3"}
            //   ]
            // },
            // {
            //   featureType: "road.highway",
            //   stylers: [
            //     {"weight": "4"}
            //   ]
            // }
          ]}
        >
          {/* Place markers on the map based on the mapLocations array */}
          {mapLocations.map((location, index) => (
            console.log(location),
            <Marker
              key={index}
              position={{ lat: location.latitude, lng: location.longitude }}
              icon={{
                path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                strokeColor: "#00B050",
                scale: 5
            }}
              
            />
          ))}
          <Marker position={map}  icon={{
        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        strokeColor: "#00B050",
        scale: 5
    }}/>

          {/* Draw lines between the pins */}
          {mapLocations.length > 1 && (
            <Polyline
              path={mapLocations.map((location) => ({
                lat: location.latitude,
                lng: location.longitude,
              }))}
              strokeColor="#FF0000" // Replace with your desired color for the line
              strokeOpacity={0.8} // Replace with your desired opacity for the line (0 to 1)
              strokeWeight={2} // Replace with your desired weight for the line
            />
          )}
        </Map>
      ) : null}

      <DispatcherNavbar locale={locale} view={view} setView={setView} />
      {children}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyA30rxaWERzJ-tl5UOIL0qrwDSBzNRrUjE", // Replace with your own Google Maps API key
})(DispatcherLayout);
