import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { VisibilityIcon, VisibilityOffIcon } from "assets/icons";
const styles = {
  label: "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1",
  field:
    "border text-3xs border-gray-50 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};
function Password(props) {
  const { name, errors, touched, label, ...rest } = props;
  const [showHidePassword, changeShowHidePassword] = useState(false);
  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field id={name} name={name}>
        {(formik) => {
          const { field } = formik;
          return (
            <div className="relative">
              <input
                type={showHidePassword ? "text" : "password"}
                className={touched && errors ? styles.fieldError : styles.field}
                {...field}
                {...rest}
              />
              {showHidePassword ? (
                <VisibilityIcon
                  className="absolute end-3 top-1/2 -mt-2 text-gray-100"
                  fontSize="inherit"
                  onClick={() => changeShowHidePassword(!showHidePassword)}
                />
              ) : (
                <VisibilityOffIcon
                  className="absolute end-3 top-1/2 -mt-2 text-gray-100"
                  fontSize="inherit"
                  onClick={() => changeShowHidePassword(!showHidePassword)}
                />
              )}
            </div>
          );
        }}
      </Field>
      <ErrorMessage component="p" className={styles.errorMsg} name={name} />
    </div>
  );
}
export default Password;
