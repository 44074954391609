import React from "react";

const Divider = ({ className, py = 5, borderColor }) => {
  return (
    <div className={`w-full relative py-${py} ${className}`}>
      <div className="absolute inset-0 flex items-center">
        <div className={`w-full border-b ${borderColor}`}></div>
      </div>
    </div>
  );
};

export default Divider;
