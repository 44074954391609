import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format, parseISO } from "date-fns";
import { CalenderWeekIcon, ArrowDownIcon } from "assets/icons";

const styles = {
  label:
    "text-gray-200 text-3xs ltr:font-gotham-medium rtl:font-semibold pb-1 ",
  field:
    "border text-3xs border-gray-50 text-dark placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded-lg appearance-none disabled:text-gray-200/50",
  errorMsg: "text-error-500 text-4xs leading-3 pt-2 pb-1",

  fieldError:
    "border text-3xs border-error-500 text-gray-200 placeholder:text-gray-100 focus:outline-none focus:border-primary-500 focus:shadow-outline focus:ring-primary-500 py-[0.69rem] w-full rounded appearance-none disabled:text-gray-200/50",
};

function DatePickerField(props) {
  const {
    value,
    name,
    errors,
    touched,
    label,
    hideErrorMessage = false,
    borderStyle,
    ...rest
  } = props;

  const today = new Date();
  const formik = useFormikContext();

  const CustomInput = ({ value, onClick, touched, errors }) => (
    <div
      className={`relative flex flex-row justify-between items-center px-3 ${
        touched && errors ? styles.fieldError : styles.field
      }`}
    >
      <CalenderWeekIcon className="text-gray-400 mr-2" size={16} />
      <span className="text-dark cursor-pointer" onClick={onClick}>
        {value}
      </span>
      <ArrowDownIcon className="text-gray-400 text-right ml-2" size={18} />
    </div>
  );

  const handleDateChange = (date) => {
    const formattedDate = format(date, "MMMM d, yyyy h:mm aa");
    formik.setFieldValue(name, formattedDate);
  };

  return (
    <div className="w-full flex flex-col pb-4">
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <div className={`w-full flex flex-col   ` + borderStyle}>
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={handleDateChange}
          showYearDropdown
          yearDropdownItemNumber={60}
          showTimeSelect
          timeFormat="h:mm aa"
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa" // Include the year in the date format
          timeInputLabel="Time:"
          customInput={<CustomInput touched={touched} errors={errors} />}
          className={touched && errors ? styles.fieldError : styles.field}
          name={name}
          selectsRange={false}
          {...rest}
        />
        {!hideErrorMessage && (
          <ErrorMessage component="p" className={styles.errorMsg} name={name} />
        )}
      </div>
    </div>
  );
}

export default DatePickerField;
