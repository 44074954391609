import { combineReducers } from "redux";
import App from "redux/app/reducer";
import Auth from "redux/auth/reducer";
import User from "redux/User/reducer";
import LanguageSwitcher from "redux/languageSwitcher/reducer";
import Userprofile from "redux/userprofile/reducer";
import Panel from "redux/userPanel/reducer";
const allReducers = combineReducers({
  App,
  Auth,
  User,
  LanguageSwitcher,
  Userprofile,
  Panel, // User Panel REDUCER
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default rootReducer;
